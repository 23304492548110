<template>
  <div class="cards-filters-tmk">


    <div class="cards-filters-tmk-side">
      <search-input
        v-if="isFilterVisible('search')"
        placeholder="Поиск по ФИО, Должности, Подразделению и Предприятию"
        style="width: 500px"
        v-model="filters.search"
        @change="filtersChanged"
      >
      </search-input>

      <user-select
        placeholder="Ответственный за этап"
        style="width: 300px"
        v-model="filters.negotiation_user_ids"
        skip-permissions
        on-current-negotiation
        @selected="filtersChanged"
      ></user-select>

      <cards-status-select
        style="width: 300px"
        v-model="filters.statuses"
        @change="filtersChanged"
      ></cards-status-select>

      <el-select
        v-model="filters.type"
        style="width: 100px"
        placeholder="Тип"
        clearable
        @change="filtersChanged"
      >
        <el-option label="КПЭ" :value="1"></el-option>
        <el-option label="ПО" :value="2"></el-option>
      </el-select>

    </div>


    <div class="cards-filters-tmk-side">
      <el-switch
        style="margin-top: 10px"
        v-model="filters.my"
        :inactive-value="null"
        active-value="1"
        inactive-text="Все"
        active-text="Мои карты"
        @change="filtersChanged"
      >
      </el-switch>
    </div>

  </div>
</template>

<script>

import SearchInput from "@/components/filters/SearchInput";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import CardsPeriodSelect from "@/components/filters/cards/CardsPeriodSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import CardsStatusSelect from "@/components/filters/cards/CardsStatusSelect.vue";

export default {
  name: "cards-page-filters",
  components: {
    CardsStatusSelect,
    UserSelect,
    CardsPeriodSelect, CardsStageNegotiationSelect, CardsStageSelect, CardsYearSelect, SearchInput},

  props: {
    value: {},
    hidden: {type: Array},
  },

  computed: {},
  watch: {},
  data() {
    return {
      filters: {
        search: null,
        negotiation_user_ids: null,
        statuses: null,
        my: null,
        type: null,
      },
    }
  },
  beforeMount() {},
  mounted() {
    this.filters = {...this.filters, ...this.value};

    this.filtersChanged()
  },
  methods: {
    filtersChanged() {
      this.$emit('input', this.filters);
      this.$emit('change', this.filters);
    },

    isFilterVisible(filterName){
      if( this.hidden && this.hidden.includes(filterName) ){
        return false;
      }
      return true;
    }
  }
}
</script>


<style lang="scss">

.cards-filters-tmk {
  display: flex;
  justify-content: space-between;

  &-side {
    display: flex;
    gap: 1.5rem;
  }
}
</style>